angular.module('fg').controller('fgFieldController', [
  '$http',
  'APP_CONFIG',
  '$scope',
  '$timeout',
  'flowinglyConstants',
  'fgUtils',
  'pubsubService',
  'currencyService',
  'flowinglyFormulaService',
  'sessionService',
  'conditionalFormService',
  function (
    $http,
    APP_CONFIG,
    $scope,
    $timeout,
    flowinglyConstants,
    fgUtils,
    pubsubService,
    currencyService,
    flowinglyFormulaService,
    sessionService,
    conditionalFormService
  ) {
    var self = this;
    var _form, _field;
    const DATABASE_API_URI = APP_CONFIG.apiBaseUrl + 'customdatabase';
    const PUBLIC_FORM_API_URI = APP_CONFIG.apiBaseUrl + 'form';

    this.init = function (fgFormCtrl, fieldSchema, editMode) {
      self.initForm(fgFormCtrl);
      self.initField(fieldSchema, editMode);
      self.initDefaultData(fieldSchema, editMode);

      $scope.form = _form;
      $scope.field = _field;
      $scope.field.stepId = fgFormCtrl != null ? fgFormCtrl.stepId : '';
      if (_field.schema.type === flowinglyConstants.formFieldType.IMAGE) {
        sessionService.getBusinessSetting('ShowLogoFields').then((value) => {
          if (value === undefined) {
            // Public form anon user can't get the business setting
            // so we only hide logo if $scope.showLogoFields===false or they will never see it
            // Flow must be re-published to remove the logo
            return;
          }
          $scope.$applyAsync(() => {
            $scope.showLogoFields = (value && value.toLowerCase()) === 'true';
          });
        });
      }

      $scope.$on('$destroy', function () {
        conditionalFormService.emptyFieldsArray();
      });

      $scope.deselectIfSelected = function (watchField, val) {
        if (
          !watchField.state.$recentValue ||
          watchField.state.$recentValue != val
        ) {
          watchField.state.$recentValue = val;
        } else {
          watchField.state.$recentValue = null;
          $scope.form.data[watchField.name] = null;
        }

        $scope.populateNotifyFields(watchField);
      };

      $scope.applyFieldConditions = function (triggerField, fieldType) {
        if (APP_CONFIG.cfEnableConditionalRules === false) {
          return;
        }
        conditionalFormService.refreshUI(triggerField, _form, fieldType);
      };

      $scope.triggerFieldConditions = function (field) {
        $scope.applyFieldConditions(field, $scope.fieldSchema.type);
      };

      $scope.populateNotifyFields = function (watchField) {
        $scope.applyFieldConditions(watchField, $scope.fieldSchema.type);
        var watchFieldValue;
        switch ($scope.fieldSchema.type) {
          case flowinglyConstants.formFieldType.CURRENCY:
            watchFieldValue = currencyService.parseNumber(
              $scope.form.data[watchField.name]
            );
            break;
          case flowinglyConstants.formFieldType.LOOKUP:
            if (
              $scope.field.schema.lookupConfig &&
              $scope.field.schema.lookupConfig.displayValueType == 'user'
            ) {
              if ($scope.field.schema.lookupConfig.userObject) {
                if ($scope.field.schema.lookupConfig.userObject.length > 0) {
                  $scope.form.data[watchField.name] =
                    $scope.field.schema.lookupConfig.userObject
                      .map(function (uo) {
                        return uo.text;
                      })
                      .join(', ');
                }
              } else $scope.form.data[watchField.name] = '';
            } else {
              watchFieldValue =
                $scope.form.values && $scope.form.values[watchField.name]
                  ? $scope.form.values[watchField.name]
                  : $scope.form.data[watchField.name];
            }
            break;
          default:
            watchFieldValue =
              $scope.form.values && $scope.form.values[watchField.name]
                ? $scope.form.values[watchField.name]
                : $scope.form.data[watchField.name];
            break;
        }
        // for manually dropdown, need use text rather than value for query the API
        if (
          (!watchField.schema.dataSource ||
            watchField.schema.dataSource === 'manually') &&
          watchField.schema.options &&
          watchField.schema.options.length > 0
        ) {
          var matchOption = watchField.schema.options.find(function (o) {
            return o.value === watchFieldValue;
          });

          if (matchOption) {
            watchFieldValue = matchOption.text;
          }
        }

        if (
          watchField.schema.notifyFields &&
          watchField.schema.notifyFields.length > 0
        ) {
          if (watchFieldValue) {
            var requestPayload = angular.copy(
              watchField.schema.notifyFields,
              []
            );
            requestPayload.forEach(function (f) {
              var watchFieldName = '';
              if (
                f.dbDataSource &&
                f.dbDataSource.filters &&
                f.dbDataSource.filters.length > 0
              ) {
                watchFieldName = f.dbDataSource.filters[0].value;
                f.dbDataSource.filters[0].value = watchFieldValue.value
                  ? watchFieldValue.value
                  : watchFieldValue;
              }
              var matchField = $scope.form.schema.fields.find(function (sf) {
                return sf.name === f.FieldName;
              });

              if (matchField) {
                f.Searchable = matchField.searchable;
                f.SearchablePageSize =
                  flowinglyConstants.searchableComboPageSize;
                if (watchFieldName) {
                  var lookupFiled = $scope.form.schema.fields.find(function (
                    sf
                  ) {
                    return (
                      sf.name === watchFieldName &&
                      sf.type === flowinglyConstants.formFieldType.LOOKUP
                    );
                  });
                  if (lookupFiled) {
                    f.previousFieldLookUpConfig = lookupFiled.lookupConfig;
                  }
                }
              }
              f.flowModelId = $scope.fieldSchema.publicForm;
            });

            const URI =
              ($scope.fieldSchema.publicForm
                ? PUBLIC_FORM_API_URI
                : DATABASE_API_URI) + '/fields/options';
            $http.post(URI, requestPayload).then(function (response) {
              response.data.forEach(function (f) {
                setNotifyFieldOptions(f.fieldName, f.options, f.tableColumnId);
              });
            });
          } else {
            // reset options
            watchField.schema.notifyFields.forEach(function (f) {
              setNotifyFieldOptions(f.fieldName, [], f.tableColumnId);
            });
          }
        }
        // Update formula values
        if (typeof $scope.form.schema !== 'undefined') {
          for (var i = 0; i < $scope.form.schema.fields.length; i++) {
            var field = $scope.form.schema.fields[i];

            // update formulas in tables
            if (
              field.type === flowinglyConstants.formFieldType.TABLE &&
              field.tableSchema
            ) {
              const tableSchema = JSON.parse(field.tableSchema);
              if (
                tableSchema.some(
                  (s) => s.type === flowinglyConstants.tableCellType.FORMULA
                )
              ) {
                const tableDataJson = $scope.form.data[field.name];
                if (tableDataJson) {
                  const tableData = JSON.parse(tableDataJson);
                  for (const cellSchema of tableSchema.filter(
                    (s) => s.type === flowinglyConstants.tableCellType.FORMULA
                  )) {
                    for (const row of tableData.rows) {
                      // if there is no data in this row then skip the row
                      if (!row.cells.find((cell) => cell.value !== undefined)) {
                        continue;
                      }
                      var formulaCell = row.cells.find(
                        (cell) => cell.id === cellSchema.id
                      );
                      if (formulaCell) {
                        const result = flowinglyFormulaService.evaluate(
                          cellSchema.formulaConfig,
                          $scope.form.schema,
                          $scope.form.data,
                          tableSchema,
                          row
                        );
                        if (result !== formulaCell.value) {
                          formulaCell.value = result;
                        }
                      }
                    }
                  }
                  $scope.form.data[field.name] = JSON.stringify(tableData);
                }
              }
            }
            // update formulas on form
            else if (
              field.type === flowinglyConstants.formFieldType.FORMULA &&
              field.formulaConfig
            ) {
              const result = flowinglyFormulaService.evaluate(
                field.formulaConfig,
                $scope.form.schema,
                $scope.form.data
              );
              $scope.form.data[field.name] = result;
            }
          }
        }
      };

      $scope.setModifiedState = function (changedFiled) {
        changedFiled.state.modified = true;
      };
    };

    this.initForm = function (fgFormCtrl) {
      _form = fgFormCtrl ? fgFormCtrl.model : {};
      return _form;
    };

    this.initField = function (fieldSchema, editMode) {
      _field = {
        $_id: 'id' + fgUtils.getUnique(),
        validationTypeName: showDataTypeInValidation(fieldSchema.type)
          ? fieldSchema.type
          : '',
        schema: fieldSchema,
        isVisible: editMode == true || !fieldSchema.hideByDefault,
        isHiddenByConditions: false
      };

      conditionalFormService.storeFieldAddedToUI(_field);

      $scope.$watch('field.schema.name', function (value, oldValue) {
        self.registerState(value);
      });
      $scope.$watch('field.schema.displayName', function (value, oldValue) {
        if (value !== oldValue)
          pubsubService.publish('WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED', value);
      });

      $timeout(function () {
        $scope.$watch(
          function () {
            return _form.data[_field.schema.name];
          },
          function () {
            $scope.populateNotifyFields(_field);
          }
        );
      }, 500);

      return _field;
    };

    this.initDefaultData = function (fieldSchema, editMode) {
      var fieldName = fieldSchema.name;

      _form.data = _form.data || {};

      if (editMode) {
        $scope.$watch('field.schema.value', function (value) {
          _form.data[fieldSchema.name] = value;
        });

        $scope.$watch('field.schema.name', function (value, oldValue) {
          if (value !== oldValue) {
            var data = _form.data[oldValue];
            delete _form.data[oldValue];
            _form.data[value] = data;
          }
        });
      } else if (
        _form.data &&
        _form.data[fieldName] === undefined &&
        fieldSchema.value !== undefined
      ) {
        _form.data[fieldName] = fieldSchema.value;
      }

      return _form.data;
    };

    this.getFieldSchema = function () {
      return _field.schema;
    };

    this.setFieldState = function (state) {
      // Called by the field-input directive
      _field.state = state;
      self.registerState(_field.schema.name);
    };

    this.registerState = function (fieldName) {
      // Re-register the ngModelCtrl with the form controller
      // whenever the name of the field has been modified.

      if (_form.state && _field.state) {
        _form.state.$removeControl(_field.state);
        _field.state.$name = fieldName;
        _form.state.$addControl(_field.state);
      }

      _field.name = fieldName;
    };

    this.field = function () {
      return _field;
    };

    this.form = function () {
      return _form;
    };

    function showDataTypeInValidation(type) {
      return (
        type === flowinglyConstants.formFieldType.NUMBER ||
        type === flowinglyConstants.formFieldType.CURRENCY ||
        type === flowinglyConstants.formFieldType.EMAIL ||
        type === flowinglyConstants.formFieldType.DATE
      );
    }

    function setNotifyFieldOptions(fieldName, values, tableColumnId) {
      var matchField = $scope.form.schema.fields.find(function (sf) {
        return sf.name === fieldName;
      });

      if (matchField) {
        if (matchField.type === flowinglyConstants.formFieldType.LOOKUP) {
          var tempArray = [];
          var watchVal;
          var isNotifyFields;
          matchField.lookupConfig.userObject = [];
          var lookupValues = [];
          if (!$scope.form.values) {
            $scope.form.values = [];
          }

          values.forEach(function (v) {
            var val;

            if (
              matchField.lookupConfig &&
              matchField.lookupConfig.displayValueType == 'user'
            ) {
              val = v.value;

              matchField.lookupConfig.userObject.push({
                value: v.value,
                text: v.text
              });
            } else {
              val = v.text;
            }
            lookupValues.push(val);
            tempArray.push(val);
            watchVal = tempArray.toString();
            typeof matchField.notifyFields === 'undefined'
              ? (isNotifyFields = 'Yes')
              : (isNotifyFields = 'No');
          });

          $scope.form.data[matchField.name] = tempArray.join(', ');
          $scope.form.values[matchField.name] = JSON.stringify(lookupValues);

          if (isNotifyFields === 'No') {
            if ($scope.form.values && $scope.form.values[matchField.name]) {
              watchVal = $scope.form.values[matchField.name];
            }
            getLookupNotifyField(matchField, watchVal);
          }
        } else {
          if (
            matchField.type === flowinglyConstants.formFieldType.TABLE &&
            tableColumnId !== undefined
          ) {
            var tableSchema = JSON.parse(matchField.tableSchema);
            var matchColumn = tableSchema.find(function (c) {
              return c.id === tableColumnId;
            });

            if (matchColumn) {
              matchColumn.options = values;
              matchColumn.filteredOptions = angular.copy(matchColumn.options);
              matchField.tableSchema = JSON.stringify(tableSchema);
            }
          } else {
            matchField.options = values;
            matchField.filteredOptions = angular.copy(matchField.options);
          }
        }
      }
    }

    function getLookupNotifyField(matchField, watchVal) {
      var requestPayload = angular.copy(matchField.notifyFields, []);

      requestPayload.forEach(function (f) {
        var watchFieldName = '';
        if (f.dbDataSource.filters && f.dbDataSource.filters.length > 0) {
          watchFieldName = f.dbDataSource.filters[0].value;
          f.dbDataSource.filters[0].value = watchVal;
        }

        var matchFieldLookup = $scope.form.schema.fields.find(function (sf) {
          return sf.name === f.FieldName;
        });

        if (matchFieldLookup) {
          f.Searchable = matchFieldLookup.searchable;
          f.SearchablePageSize = flowinglyConstants.searchableComboPageSize;
          if (watchFieldName) {
            var lookupFiled = $scope.form.schema.fields.find(function (sf) {
              return (
                sf.name === watchFieldName &&
                sf.type === flowinglyConstants.formFieldType.LOOKUP
              );
            });

            if (lookupFiled) {
              f.previousFieldLookUpConfig = lookupFiled.lookupConfig;
            }
          }
        }
        f.flowModelId = $scope.fieldSchema.publicForm;
      });

      const URI =
        ($scope.fieldSchema.publicForm
          ? PUBLIC_FORM_API_URI
          : DATABASE_API_URI) + '/fields/options';
      $http.post(URI, requestPayload).then(function (response) {
        response.data.forEach(function (f) {
          setNotifyFieldOptions(f.fieldName, f.options, f.tableColumnId);
        });
      });
    }
  }
]);
