angular.module('fg').directive('fgEditCanvasFieldProperties', [
  'fgUtils',
  'flowinglyKBService',
  'fileService',
  'workflowStore',
  'flowinglyModelUtilityService',
  'workflowFieldVariableService',
  'flowinglyConstants',
  '$timeout',
  function (
    fgUtils,
    flowinglyKBService,
    fileService,
    workflowStore,
    flowinglyModelUtilityService,
    workflowFieldVariableService,
    flowinglyConstants,
    $timeout
  ) {
    return {
      templateUrl:
        'angular-form-gen/edit/canvas/field/properties/properties.ng.html',
      scope: {
        field: '=fgEditCanvasFieldProperties',
        allFields: '=allFields',
        nodeKey: '=?',
        nodeId: '=?'
      },
      link: {
        post: function ($scope) {
          $scope.$watch('fieldPropertiesForm.$invalid', function (newValue) {
            $scope.field.$$_invalid = newValue;
          });

          $scope.field.nodeId = $scope.nodeId;
          $scope.fieldTypesToFilterOutForInstructionVariables = [
            flowinglyConstants.formFieldType.INSTRUCTION
          ];

          if ($scope.field.displayName === 'Instruction') {
            fileService
              .replaceFileIdsWithImages($scope.field.value)
              .then((result) => {
                $scope.field.value = result;
              });

            $scope.$on('kendoWidgetCreated', function (event, widget) {
              //to append third party fonts we need to access iframe->head object inside kendo editor and link font.
              angular
                .element(
                  angular
                    .element('.k-editable-area .k-content')[0]
                    .contentDocument.getElementsByTagName('head')
                )
                .append(
                  "<link href='https://fonts.googleapis.com/css?family=Open+Sans:400,600,700' rel='stylesheet' type='text/css'>"
                );

              var styleTag =
                widget.body.parentNode.getElementsByTagName('style')[0];
              styleTag.parentNode.removeChild(styleTag);
              widget.body.style.fontFamily = '"Open Sans", sans-serif';
              widget.body.style.fontSize = '13px';
              widget.body.style.color = '#35384D';

              // Find all the text editor and bind the execute function.
              let editorElements = $('[data-text-editor="instruction"]');

              try {
                editorElements.toArray().forEach((element) => {
                  let editor = $(element).data('kendoEditor');
                  if (editor) {
                    editor.bind('execute', editorExecute);
                  }
                });
              } catch (e) {
                // Swallow error and prevent code termination.
              }
            });

            $scope.allStepVariables = [];
            $scope.showAddVariableButton = true;
            constructVariableDropdown();

            $scope.toggleDropDown = function () {
              $('.variable-all-group').slideToggle(150);
            };

            $scope.selectVariable = function ($event) {
              $scope.toggleDropDown();
              var value = escapeSpecialChar(
                $($event.currentTarget).data('itemValue')
              );
              var editor = $(
                '.field-settings.visible .custom-email-editor textarea.k-content'
              ).data('kendoEditor');
              editor.exec('inserthtml', { value: `${value}` });
            };

            function constructVariableDropdown() {
              var nodeDataArray = workflowStore.getNodeDataArray();
              var currentNode = flowinglyModelUtilityService.getNodeById(
                nodeDataArray,
                $scope.nodeId
              );
              $scope.showAddVariableButton = !(
                currentNode.stepType === flowinglyConstants.stepType.PUBLIC_FORM
              );
              $scope.allStepVariables =
                workflowFieldVariableService.getPriorNodesVariables(
                  $scope.nodeId,
                  $scope.fieldTypesToFilterOutForInstructionVariables
                );
            }

            function escapeSpecialChar(value) {
              value = value
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');
              return value;
            }

            $scope.toggleVariableGroup = function ($event) {
              var groupHeader = $($event.currentTarget);
              groupHeader.toggleClass('variable-group-collapsed');
              var dropdown = groupHeader.next('.variable-dropdown');
              dropdown.slideToggle(150);
            };

            $scope.options = [
              'formatting',
              'bold',
              'italic',
              'underline',
              'fontSize',
              'strikethrough',
              'justifyLeft',
              'justifyCenter',
              'justifyRight',
              'justifyFull',
              'insertUnorderedList',
              'insertOrderedList',
              'indent',
              'outdent',
              'createLink',
              'unlink',
              'foreColor',
              'backColor',
              'insertImage',
              'insertFile',
              {
                name: 'insertVideo',
                tooltip: 'Embed Youtube Video',
                exec: (e) =>
                  $scope.$broadcast('EditorYoutubeClicked', {
                    event: e,
                    selector: `#${$scope.field.name}-textarea`
                  })
              },
              'viewHtml',
              'separator',
              {
                name: 'customTemplate',
                template: `<div class="add-variable" ng-show="showAddVariableButton">
                              <a ng-click="toggleDropDown()"
                                  class="btn"
                                  ng-class="blue">Add Variable</a>
                              <div class="variable-all-group">
                                  <div class="variable-group">
                                      <div class="variable-group-header variable-group-collapsed" ng-click="toggleVariableGroup($event)"><span>Flow Attributes</span></div>
                                      <ul class="variable-dropdown">
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.subject}"><span>Subject</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.initiatorFullName}"><span>Initiator Full Name</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.initiatorEmail}"><span>Initiator Email</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.initiatorManager}"><span>Initiator Manager</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.initiatorPhoneNumber}"><span>Initiator Phone Number</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.status}"><span>Status</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.nextStep}"><span>Next Step</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.dueDate}"><span>Due Date</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.flowModelName}"><span>Flow Model Name</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.flowId}"><span>Flow ID</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.progress}"><span>Progress</span></li>
                                          <li ng-click="selectVariable($event)" data-item-value="{flow.link}"><span>Flow Link</span></li>
                                      </ul>
                                  </div>
                                  <div ng-repeat="stepVariables in allStepVariables" class="variable-group">
                                      <div class="variable-group-header variable-group-collapsed" ng-click="toggleVariableGroup($event)"><span>{{stepVariables.stepName}}</span></div>
                                      <ul class="variable-dropdown">
                                          <li ng-repeat="fieldName in stepVariables.fieldNames" ng-click="selectVariable($event)"
                                              data-item-value="{step.{{stepVariables.stepName}}.{{fieldName}}}">
                                              <span title='{{fieldName}}'>{{fieldName}}</span>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>`
              }
            ];

            function editorExecute(e) {
              setTimeout(function () {
                if (e.name === 'createlink') {
                  const form = e.command._dialog.element;
                  const selectors = ['#k-editor-link-target'];
                  restyleCheckBoxes(form, selectors);
                }
              }, 0);
            }

            function restyleCheckBoxes(form, targetClasses) {
              // FLOW-6400 -To get checkbox in Insert Hyperlink kendo pop-up to style similar to others in Flowingly,
              // removing the kendo checkbox default class so that global Flowingly css checkbox style will take effect.
              targetClasses.forEach((selector) => {
                const linkTarget = form.find(selector);
                if (linkTarget) {
                  linkTarget.removeClass('k-checkbox');
                }
              });
            }
          } else if ($scope.field.type === 'selectlist') {
            $scope.onChangeCallback = function () {
              initializeFieldDataSource();
            };
            initializeFieldDataSource();
          } else if ($scope.field.type === 'lookup') {
            if (!$scope.field.lookupConfig) {
              $scope.field.lookupConfig = {
                dbName: '',
                displayValue: '',
                queryValue: '',
                value: undefined
              };
            }
          } else if ($scope.field.type === 'formula') {
            flowinglyKBService
              .getKbLink('properties.' + $scope.field.type)
              .then(function (data) {
                $scope.kbLink = data;
              });
          } else if ($scope.field.type === 'attachdocument') {
            //place all attach document properties here
          }

          $scope.renderInfo = fgUtils.getRenderInfo($scope.field);

          $scope.initializeFieldDataSource = initializeFieldDataSource;
          function initializeFieldDataSource() {
            var dsType = $scope.field.dataSource;
            var isManualType = !dsType || dsType == 'manually';

            $scope.dataSourceOptions = [
              {
                value: 'manually',
                text: 'Populate manually'
              },
              {
                value: 'database',
                text: 'Database'
              }
            ];

            if (isManualType) {
              $scope.field.dataSource = 'manually';
              $scope.field.dbDataSource = undefined;
            } else if (!$scope.field.dbDataSource) {
              $scope.field.dbDataSource = {
                dbName: '',
                displayValue: '',
                filters: [{}]
              };
            }
          }
        }
      }
    };
  }
]);
