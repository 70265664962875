///
/// A directive for displaying a list of uploaded files
///
angular.module('fgFiles').directive('fgFileList', [fgFileList]);

function fgFileList() {
  var directive = {
    restrict: 'E',
    require: ['?^fgForm', 'fgFileList'],
    scope: {
      //id of the file upload control associated with this list
      fieldId: '<',
      fileUploadRequired: '<',
      form: '='
    },
    bindToController: true,
    controller: [
      '$rootScope',
      '$scope',
      '$q',
      '$http',
      'flowStore',
      'pubsubService',
      'lodashService',
      'notificationService',
      'ngDialog',
      'fgFileListService',
      '$injector',
      function controller(
        $rootScope,
        $scope,
        $q,
        $http,
        flowStore,
        pubsubService,
        lodashService,
        notificationService,
        ngDialog,
        fgFileListService,
        $injector
      ) {
        if ($injector.has('runnerPublicFormApiService')) {
          var runnerPublicFormApiService = $injector.get(
            'runnerPublicFormApiService'
          );
        }

        var ctrl = this;
        ctrl.fgFileListService = fgFileListService;
        ctrl.touched = false;
        ctrl.init = init;
        ctrl.getFiles = getFiles;
        ctrl.removeFile = removeFile;
        ctrl.removeAll = removeAll;
        ctrl.showFileRequiredMessage = showFileRequiredMessage;
        ctrl.files = [];

        // PUBLIC  METHODS  /////////////////////////////////////////////////////////////

        // Get files saved against this step (uses the step ID passed to the formCtrl to filter)
        function getFiles() {
          ctrl.files = fgFileListService.getFilesForControl(
            ctrl.fieldId,
            ctrl.stepId
          );
          return ctrl.files;
        }

        function init(fgFormCtrl) {
          if (fgFormCtrl != null) {
            ctrl.fgFormCtrl = fgFormCtrl;
            ctrl.stepId = fgFormCtrl.stepId;
            ctrl.flowInstanceId = fgFormCtrl.flowInstanceId;
            fgFileListService.storeForm(fgFormCtrl);
            fgFileListService.registerFileControl(
              this.fieldId,
              ctrl.stepId,
              ctrl.flowInstanceId,
              ctrl.fileUploadRequired
            );
            validateFileRequired();
            pubsubService.subscribe(
              'FILEUPLOAD_UPLOAD_COMPLETED',
              validateFileRequired,
              'file-list' + ctrl.fieldId
            );
          }
        }

        function removeAll() {
          confirmDelete().then(function () {
            lodashService.each(ctrl.files, function (file) {
              removeFile(file, true);
            });
          });
        }

        function removeFile(file, supressConfirmation) {
          confirmDelete(supressConfirmation).then(
            function () {
              pubsubService.publish('FILEUPLOAD_REMOVAL_STARTED');
              //remove file from client first (optimisitic) this prevents the UI trying to upload in progress form files
              //if the user moves somewhere else whilst delete in progress.
              fgFileListService.removeFile(file, ctrl.fieldId, ctrl.stepId);
              //user confirmed delete
              var user = $rootScope.sessionService.getUser();
              var deletePromise;
              if (user == undefined) {
                deletePromise = runnerPublicFormApiService.deleteAnonymousFile(
                  file.id,
                  ctrl.stepId
                );
              } else {
                deletePromise = $http.post(
                  `${$rootScope.APP_CONFIG.apiBaseUrl}files/remove/${user.businessId}/${user.id}/${file.id}/${ctrl.stepId}`,
                  {}
                );
              }
              deletePromise.then(function () {
                pubsubService.publish(
                  'FILEUPLOAD_REMOVAL_COMPLETED',
                  fgFileListService.getFilesForControl(file.key, ctrl.stepId)
                    .length
                );
                notificationService.showSuccessToast(
                  file.filename + ' Removed'
                );
                validateFileRequired();
              });
            },
            function () {
              //user cancelled do nothing
            }
          );
        }

        // PRIVATE METHODS  /////////////////////////////////////////////////////////////
        function removeFileFromStepData(fileToRemove) {
          var files = ctrl.form.data[fileToRemove.key];
          if (files) {
            ctrl.form.data[fileToRemove.key] = files.filter(function (file) {
              return file.fileId !== fileToRemove.id;
            });
            if (ctrl.form.data[fileToRemove.key].length === 0)
              ctrl.form.data[fileToRemove.key] = null;
          }
        }

        function confirmDelete(supressConfirmation) {
          if (flowStore.get('dontShowAgain') != undefined) {
            supressConfirmation = true;
          }

          //supress the confirmation if the user clicked removeAll and they have already confirmed delete
          if (!supressConfirmation) {
            //todo to reusable service
            return ngDialog
              .openConfirm({
                className: 'ngdialog-theme-plain',
                showClose: false,
                template:
                  'angular-form-gen/form/files/file.confirmDelete.ng.html',
                scope: $scope
              })
              .then(
                function (dontShowAgain) {
                  setDontShowAgainFlag(dontShowAgain);
                  return $q.when();
                },
                function () {
                  return $q.reject();
                }
              );
          }
          return $q.when();
        }

        function showFileRequiredMessage() {
          return (
            fgFileListService.isFileRequired(ctrl.fieldId, ctrl.stepId) &&
            (fgFileListService.isFileListDirty(ctrl.fieldId, ctrl.stepId) ||
              (ctrl.form.state && ctrl.form.state.$submitted)) &&
            fgFileListService.getFilesForControl(ctrl.fieldId, ctrl.stepId)
              .length == 0
          );
        }

        function validateFileRequired() {
          //check to to see if the form is invalid as there are not files uploaded when they are required.
          //the form may  have  mix of optional and required fields, so we only want to look at the required ones.
          if (fgFileListService.isFileRequired(ctrl.fieldId, ctrl.stepId)) {
            var invalid =
              fgFileListService.getFilesForControl(ctrl.fieldId, ctrl.stepId)
                .length == 0;

            if (invalid) {
              pubsubService.publish('FILEUPLOAD_FILE_ERROR', {
                fileControlId: ctrl.fieldId,
                flowInstanceId: ctrl.flowInstanceId
              });
            } else {
              pubsubService.publish('FILEUPLOAD_FILE_VALID', {
                fileControlId: ctrl.fieldId,
                flowInstanceId: ctrl.flowInstanceId
              });
            }
          }
        }
        function setDontShowAgainFlag(dontShowAgain) {
          if (dontShowAgain) {
            flowStore.set('dontShowAgain', true);
          }
        }
      }
    ],
    controllerAs: 'ctrl',
    link: function (scope, element, attrs, ctrls) {
      var fgFormCtrl = ctrls[0];
      var fgFileListCtrl = ctrls[1];
      fgFileListCtrl.init(fgFormCtrl);
    },

    template: [
      '<div ng-show="ctrl.files.length > 1" class="pt-5"><a class="ml-15 cursor-pointer" ng-click="ctrl.removeAll()">Remove All</a></div>',
      '<div><ul class="file-list">',
      '<div ng-if="ctrl.showFileRequiredMessage()" class="error-text">This is a required field.</div>',
      '<li ng-repeat="file in ctrl.getFiles() | orderBy : \'filename\'" class="clearfix">',
      '<a flow-download-link="{{::file.downloadLink}}" target="_blank"> {{::file.filename}}</a><span>({{::file.size}}K)</span>',
      '<i ng-click="ctrl.removeFile(file)" class="fa-light fa-xmark fa-lg"></i></li>',
      '</ul>',
      '<div class="pl-10 pt-6 secondary-text" ng-show="ctrl.files.length === 0"><em>Drag and Drop your files here or click the Upload button</em></div>',
      '</div>'
    ].join('')
  };
  return directive;
}
