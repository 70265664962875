angular
  .module('fg')
  .directive('fgFieldInstruction', [
    'validationService',
    '$sce',
    'fgFieldInstructionLinkFn',
    'pubsubService',
    function (validationService, $sce, fgFieldInstructionLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/instruction.ng.html',
        scope: true,
        controllerAs: 'ctrl',
        controller: [
          '$scope',
          'pubsubService',
          'fileService',
          function ($scope, pubsubService, fileService) {
            var ctrl = this;

            ctrl.init = init;
            ctrl.field = null;
            ctrl.form = null;
            ctrl.instructionText = null;

            function init(field, form) {
              ctrl.field = field;
              ctrl.form = form;
              setInstructionText();
            }

            function setInstructionText() {
              let rawValue = null;

              if ($scope.fieldSchema.isNodeFromRunnerProcessMap) {
                rawValue = $scope.fieldSchema.value;
              } else {
                rawValue = ctrl.form.data[$scope.field.schema.name]
                  ? ctrl.form.data[$scope.field.schema.name]
                  : ctrl.field.schema.value;
              }

              ctrl.instructionText = validationService.sanitizeString(
                $sce.trustAsHtml(rawValue)
              );

              fileService.replaceFileIdsWithImages(rawValue).then((result) => {
                ctrl.instructionText = validationService.sanitizeString(
                  $sce.trustAsHtml(result)
                );
              });
            }

            // Subscribe to the fields updated and refresh binding with the new instruction value.
            pubsubService.subscribe(
              'WORKFLOW_FIELDS_UPDATED',
              function (event, fields) {
                if (fields != null) {
                  var instructionField = fields.filter(
                    (item) => item.Name === $scope.field.schema.name
                  );
                  if (
                    instructionField != null &&
                    instructionField.length !== 0
                  ) {
                    ctrl.instructionText = validationService.sanitizeString(
                      $sce.trustAsHtml(instructionField[0].Instruction)
                    );
                    fileService.addFileDownloadClickEventListener();
                  }
                }
              }
            );
          }
        ],
        link: fgFieldInstructionLinkFn
      };
    }
  ])
  .factory('fgFieldInstructionLinkFn', function () {
    return function ($scope, $element, $attrs, ctrls) {};
  });
