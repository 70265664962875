angular
  .module('fg')
  .directive('fgFieldSelectList', [
    'fgFieldSelectListLinkFn',
    function (fgFieldSelectListLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/selectlist.ng.html',
        scope: true,
        link: fgFieldSelectListLinkFn
      };
    }
  ])
  .factory('fgFieldSelectListLinkFn', [
    '$timeout',
    '$http',
    'APP_CONFIG',
    'flowinglyConstants',
    function ($timeout, $http, APP_CONFIG, flowinglyConstants) {
      return function ($scope, $element, $attrs, ctrls) {
        var DATABASE_API_URI = APP_CONFIG.apiBaseUrl + 'customdatabase';
        var PUBLIC_FORM_API_URI = APP_CONFIG.apiBaseUrl + 'form';

        // There are few flows models which has first option as Please Choose
        // which should be removed from field as below placeholder is being added for same purpose.
        if (
          $scope.field.schema.options &&
          $scope.field.schema.options.length > 0 &&
          $scope.field.schema.options[0].text === 'Please Choose' &&
          $scope.field.schema.options[0].value === ''
        ) {
          $scope.field.schema.options.splice(0, 1);
        }

        //this is placeholder text used in kendo control.
        $scope.options = {
          optionLabel: 'Please Choose...',
          dataBound: disableOuterScrolling,
          filter: $scope.field.schema.searchable === true ? 'contains' : 'none'
        };

        $scope.field.schema.filteredOptions = angular.copy(
          $scope.field.schema.options
        );

        if ($scope.field.schema.searchable === true) {
          $timeout(function () {
            if (
              $scope.field.schema.filteredOptions.length >
                flowinglyConstants.searchableComboPageSize &&
              $scope.form.data[$scope.field.schema.name] &&
              $scope.form.data[$scope.field.schema.name] !== ''
            ) {
              $scope.field.schema.filteredOptions =
                $scope.field.schema.filteredOptions
                  .filter(function (option) {
                    return (
                      option.text.indexOf(
                        $scope.form.data[$scope.field.schema.name]
                      ) >= 0
                    );
                  })
                  .slice(0, flowinglyConstants.searchableComboPageSize);
            } else {
              $scope.field.schema.filteredOptions =
                $scope.field.schema.filteredOptions.slice(
                  0,
                  flowinglyConstants.searchableComboPageSize
                );
            }

            $scope.options.filtering = function (event) {
              var filter = event.filter;
              event.preventDefault();

              if ($scope.field.schema.dataSource === 'manually') {
                if (!filter.value || filter.value === '') {
                  $scope.field.schema.filteredOptions = angular.copy(
                    $scope.field.schema.options
                  );
                } else {
                  $scope.field.schema.filteredOptions =
                    $scope.field.schema.options.filter(function (val) {
                      return (
                        val.text
                          .toLowerCase()
                          .indexOf(filter.value.toLowerCase()) >= 0
                      );
                    });
                }

                $scope.field.schema.filteredOptions =
                  $scope.field.schema.filteredOptions.slice(
                    0,
                    flowinglyConstants.searchableComboPageSize
                  );

                $timeout(function () {
                  this.setDataSource($scope.field.schema.filteredOptions);
                  this.refresh();
                });
              } else {
                var requestPayload = {
                  FieldName: $scope.field.name,
                  DbDataSource: angular.copy($scope.field.schema.dbDataSource),
                  FlowModelId: $scope.field.schema.publicForm,
                  Searchable: true,
                  SearchablePageSize:
                    flowinglyConstants.searchableComboPageSize,
                  SearchTerm: filter.value
                };

                if (
                  $scope.field.schema.dbDataSource &&
                  $scope.field.schema.dbDataSource.filters &&
                  $scope.field.schema.dbDataSource.filters.length > 0
                ) {
                  requestPayload.DbDataSource.filters[0].value =
                    $scope.form.data[
                      $scope.field.schema.dbDataSource.filters[0].value
                    ];
                }

                var URI =
                  ($scope.field.schema.publicForm
                    ? PUBLIC_FORM_API_URI
                    : DATABASE_API_URI) + '/fields/options';
                $http.post(URI, [requestPayload]).then(function (response) {
                  if (response.data && response.data.length > 0) {
                    $scope.field.schema.options = response.data[0].options;
                    $scope.field.schema.filteredOptions = angular.copy(
                      $scope.field.schema.options
                    );
                  }
                });
              }
            };
          });
        }

        function disableOuterScrolling(e) {
          $('.k-list-scroller').on('mousewheel DOMMouseScroll', function (e) {
            if (e.currentTarget.scrollHeight === e.currentTarget.offsetHeight) {
              return;
            }

            var delta = 0;
            if (e.originalEvent.wheelDelta) {
              // will work in most cases
              delta = e.originalEvent.wheelDelta;
            } else if (e.originalEvent.detail) {
              // fallback for Firefox
              delta = -e.originalEvent.detail;
            }
            var scrollTop = $(e.currentTarget).scrollTop();
            if (
              (delta < 0 &&
                scrollTop ==
                  e.currentTarget.scrollHeight -
                    e.currentTarget.offsetHeight) ||
              (delta > 0 && scrollTop == 0)
            ) {
              e.preventDefault();
            }
          });
        }
      };
    }
  ]);
